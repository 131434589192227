import {post,get,getBase64} from "@/config/http";
export function SaveVideo(data){//
    return post(
        '/video',
        data
    )
}
export function FindAllVideo(data){//
    return get(
        '/video',
        data
    )
}
