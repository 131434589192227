import {Toast} from "vant";

export function checkSignInForm(form) {
    if (!form.account || !form.pwd) {
        Toast.fail("请输入账号和密码")
        return false
    }
    if (form.pwd.length < 6 || form.pwd.length > 20) {
        Toast.fail("请输入正确的密码")
        return false
    }
    return true
}

export function checkSignUpForm(form) {
    if (!form.account || !form.pwd || !form.nick_name || !form.sex) {
        Toast.fail("请填写完整注册表单")
        return false
    }
    if (!form.account) {
        Toast.fail("请输入账号")
        return false
    }
    if (form.nick_name.length < 3 || form.nick_name.length > 10) {
        Toast.fail("请输入3~10个字符的昵称")
        return false
    }
    if (form.pwd.length < 6 || form.pwd.length > 20) {
        Toast.fail("请输入6~20个字符的密码")
        return false
    }
    if (!form.sex.length) {
        Toast.fail("请输入性别")
        return false
    }
    return true
}

export function checkVideoForm(form) {
    if (!form.title) {
        Toast.fail("请输入视频标题")
        return false
    }
    if (form.title.length > 20) {
        Toast.fail("视频标题不能超过20个字符")
        return false
    }
    if (!form.fid) {
        Toast.fail("还没有上传视频")
        return false
    }
    if (!form.type) {
        Toast.fail("请选择稿件类型")
        return false
    }
    return true
}

export function checkArticleForm(form) {
    if (!form.title) {
        Toast.fail("请输入文章标题")
        return false
    }
    if (form.title.length > 20) {
        Toast.fail("文章标题不能超过20个字符")
        return false
    }
    if (!form.content) {
        Toast.fail("请输入正文")
        return false
    }
    return true
}
